import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import {
  Form,
  Container,
  Row,
  Col,
  Image,
  Button,
  InputGroup,
} from "react-bootstrap";
import { message } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faL, faXmark } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";

function UpdateMenuItems() {
  const { id } = useParams();
  const [Currency, setCurrency] = useState(1);
  const [menu, setMenu] = useState([]);
  const [menuitem, setMenuItem] = useState("");
  const [menuid, setMenuId] = useState(-1);
  const [price, setPrice] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [description, setDescription] = useState("");
  const [img, setImg] = useState("");
  const count = 1;
  const [selectedToppings, setSelectedToppings] = useState([]);
  const [selectedBases, setSelectedBases] = useState([]);
  const [isBaseSelector, setIsBaseSelector] = useState(false);
  const [isBaseSelectorView, setIsBaseSelectorView] = useState(false);
  const [showBaseError, setShowBaseError] = useState(false);
  const [basePrices, setBasePrices] = useState({});
  const [showError, setShowError] = useState(false);
  const [isToppingSelectorView, setIsToppingSelectorView] = useState(false);
  const [isToppingSelector, setIsToppingSelector] = useState(false);
  const [prices, setPrices] = useState({});
  const [toppings, setToppings] = useState([]);
  const [bases, setBases] = useState([]);
  const [stock, setIsChecked] = useState(false);

  console.log(stock, "log");

  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState(0);
  const [imageDimensions, setImageDimensions] = useState({
    height: "300px",
    width: "450px",
  });
  const [confirmationStatus, setConfirmationStatus] = useState(false);
  const adminId = localStorage.getItem("fosadminsecretsID");

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_FOS_API}/getmenudata`, {
        headers: { Authorization: adminId },
      })
      .then((res) => setMenu(res.data))
      .catch((err) => {
        console.log(err);
        message.error("Server Error");
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_FOS_API}/getmenuitemsdesireddata/` + id, {
        headers: { Authorization: adminId },
      })
      .then((res) => {
        const data = res.data;
        setMenuItem(data.menuitem);
        setMenuId(data.menuid);
        setPrice(data.price);
        setQuantity(data.quantity);
        setDescription(data.description);
        setImg(data.image);
        setSelectedToppings(data.selectedToppings || []);
        setSelectedBases(data.selectedBases || []);
        setIsChecked(data.stock === "true" || data.stock === true);
      })

      .catch((err) => {
        console.log(err);
        message.error("Server Error");
      });
  }, []);

  const handleBaseAddClick = () => {
    const missingPrices = selectedBases.filter(
      (Base) => Base.basePrices === ""
    );

    if (missingPrices.length > 0) {
      setShowBaseError(true);
    } else {
      setShowBaseError(false);
      setIsBaseSelectorView(true);
      setIsBaseSelector(false);
    }
  };

  const handleBaseCloseClick = () => {
    setIsBaseSelector(false);
    if (!isBaseSelectorView) {
      setSelectedBases([]);
      setBasePrices({});
    }
  };

  const handleAddClick = () => {
    const missingPrices = selectedToppings.filter(
      (topping) => topping.price === ""
    );

    if (missingPrices.length > 0) {
      setShowError(true);
    } else {
      setShowError(false);
      setIsToppingSelectorView(true);
      setIsToppingSelector(false);
    }
  };
  const handleCloseClick = () => {
    setIsToppingSelector(false);
    if (!isToppingSelectorView) {
      setSelectedToppings([]);
      setPrices({});
    }
  };

  const handleCheckboxChange = (item) => {
    if (
      selectedToppings.some((topping) => topping.toppingId === item.toppingId)
    ) {
      setSelectedToppings(
        selectedToppings.filter(
          (topping) => topping.toppingId !== item.toppingId
        )
      );
    } else {
      setSelectedToppings([
        ...selectedToppings,
        { toppingId: item.toppingId, price: "", toppingName: item.toppingName },
      ]);
    }
  };

  const handlePriceChange = (item, price) => {
    const updatedSelectedToppings = selectedToppings.map((topping) => {
      if (topping.toppingId === item.toppingId) {
        return {
          toppingId: item.toppingId,
          price,
          toppingName: item.toppingName,
        };
      }
      return topping;
    });

    setSelectedToppings(updatedSelectedToppings);
    setPrices({
      ...prices,
      [item.toppingId]: price,
    });
  };
  const handleBaseCheckboxChange = (item) => {
    if (selectedBases.some((base) => base.baseId === item.baseId)) {
      setSelectedBases(
        selectedBases.filter((base) => base.baseId !== item.baseId)
      );
    } else {
      setSelectedBases([
        ...selectedBases,
        { baseId: item.baseId, baseName: item.baseName, basePrice: "" },
      ]);
    }
  };
  const handleBasePriceChange = (item, price) => {
    const updatedSelectedBases = selectedBases.map((base) => {
      if (base.baseId === item.baseId) {
        return { ...base, basePrice: price };
      }
      return base;
    });

    setSelectedBases(updatedSelectedBases);
    setBasePrices({
      ...basePrices,
      [item.baseId]: price,
    });
  };
  useEffect(() => {
    fetchToppings();
  }, []);
  const fetchToppings = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_FOS_API + "/gettopping",
        {
          headers: { Authorization: adminId },
        }
      );
      setToppings(response.data);
    } catch (error) {
      console.error("Error fetching toppings:", error);
      message.error("Server Error");
    }
  };
  useEffect(() => {
    fetchBases();
  }, []);
  const fetchBases = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_FOS_API + "/getBase",
        {
          headers: { Authorization: adminId },
        }
      );
      setBases(response.data);
    } catch (error) {
      console.error("Error fetching Bases:", error);
      message.error("Server Error");
    }
  };

  const handleFile = (e) => {
    setFile(e.target.files[0]);
    setPreview(URL.createObjectURL(e.target.files[0]));
  };

  function handleUpdate(e) {
    e.preventDefault();
    const formdata = new FormData();
    formdata.append("image", file);
    formdata.append("menuitem", menuitem);
    formdata.append("menuid", menuid);
    formdata.append("price", price);
    formdata.append("quantity", quantity);
    formdata.append("description", description);
    formdata.append("count", count);
    formdata.append("selectedToppings", JSON.stringify(selectedToppings));
    formdata.append("selectedBases", JSON.stringify(selectedBases));
    formdata.append("stock", stock);

    // Added log to check the form data before sending
    console.log("Form data before sending:", {
      menuitem,
      menuid,
      price,
      quantity,
      description,
      selectedToppings,
      selectedBases,
      stock,
    });

    axios
      .put(`${process.env.REACT_APP_FOS_API}/updateproduct/${id}`, formdata, {
        headers: { Authorization: adminId },
      })
      .then((res) => {
        if (!confirmationStatus) {
          setConfirmationStatus(true);
          setTimeout(() => {
            setConfirmationStatus(false);
          }, 2000);
        }
        Swal.fire({
          icon: "success",
          title: "Updated Successfully",
          showConfirmButton: false,
          timer: 2000,
        });
        navigate("/viewmenuitems");
      })
      .catch((err) => {
        console.log("-> from client" + err);
        message.error("Server Error");
      });
  }

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 500) {
        setImageDimensions({
          height: "200px",
          width: "250px",
        });
      } else {
        setImageDimensions({
          height: "300px",
          width: "450px",
        });
      }
    }

    // Add an event listener for window resize
    window.addEventListener("resize", handleResize);

    // Call the resize handler initially
    handleResize();

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Container>
      {confirmationStatus === true ? (
        <p
          style={{
            width: "13rem",
            position: "absolute",
            right: 5,
            zIndex: "9999",
          }}
          className="successfullanimation"
        >
          Successfully added 🥳
        </p>
      ) : (
        ""
      )}
      <div className="py-3 text-center">
        <h5 style={{ color: "orange" }}>
          <b>UPDATE MENUITEMS FORM</b>
        </h5>
      </div>
      <Form onSubmit={handleUpdate}>
        <Row>
          <Col lg={6} xs={12} className="px-4">
            <Form.Group>
              <Form.Label>Menu Item Name</Form.Label>
              <Form.Control
                type="text"
                value={menuitem}
                onChange={(e) => setMenuItem(e.target.value)}
                placeholder="Pizza"
                required
              />
            </Form.Group>
            <Row>
              <Col lg={6} xs={12}>
                <Form.Group>
                  <Form.Label>Select Categories</Form.Label>
                  <Form.Select onChange={(e) => setMenuId(e.target.value)}>
                    <option style={{ background: "#428FF5" }} value="">
                      {menu.find((m) => m.menuid == menuid)?.menuname}
                    </option>
                    {menu?.map((m) =>
                      m.menuid != menuid ? (
                        <option value={m.menuid}>{m.menuname}</option>
                      ) : (
                        ""
                      )
                    )}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col lg={6} xs={12}>
                <Form.Group>
                  <Form.Label>Currency</Form.Label>
                  <Form.Select
                    onChange={(e) => setCurrency(e.target.value)}
                    required
                    
                  >
                  
                    <option value={2}>USD</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg={6} xs={12}>
                <Form.Group>
                  <Form.Label>Quantity</Form.Label>
                  <Form.Control
                    type="text"
                    value={quantity}
                    max="100"
                    min="1"
                    onChange={(e) => setQuantity(e.target.value)}
                    placeholder="01"
                    required
                  />
                </Form.Group>
              </Col>
              <Col lg={6} xs={12}>
                <Form.Group>
                  <Form.Label>Price</Form.Label>
                  <InputGroup>
                    {Currency == 1 ? (
                      <span className="input-group-text">$ </span>
                    ) : (
                      <span className="input-group-text">$</span>
                    )}
                    <Form.Control
                      type="text"
                      max="100000"
                      min="1"
                      value={price}
                      onChange={(e) => setPrice(e.target.value)}
                      placeholder="100"
                      required
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>
            <Form.Group>
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                rows={5}
                placeholder="Message"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Product Image</Form.Label>
              <Form.Control
                onChange={handleFile}
                style={{
                  padding: "0.5rem 1rem",
                  height: "3rem",
                }}
                accept=".jpg, .jpeg, .png, .gif"
                type="file"
              />
            </Form.Group>
          </Col>
          <Col lg={5} xs={12}>
            <Image
              className="food_1 py-3 px-3 ps-5 border"
              src={
                preview === 0
                  ? `${process.env.REACT_APP_FOS_API}/images/${img}`
                  : preview
              }
              alt=""
              style={imageDimensions}
            />
            <div className="d-flex w-100 align-item-center justify-content-between border p-2 bg-light">
              <div className="d-flex justify-content-between mt-2 light-border">
                <Form.Label className="px-2 fw-bold">
                  Discount Active
                </Form.Label>
                <label className="switch">
                  <input type="checkbox" />
                  <span className="slider round"></span>
                </label>
              </div>
              <div className="d-flex justify-content-between mt-2 light-border">
                <Form.Label className="px-2 fw-bold">Out Of Stock </Form.Label>

                <label className="switch">
                  <input
                    checked={!stock}
                    onChange={() => setIsChecked(!stock)}
                    type="checkbox"
                  />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
            <div className="d-flex justify-content-between py-2 light-border py-4">
              <div className="d-flex justify-content-between w-100 me-5">
                <h6>Add Toppings</h6>
                {!isToppingSelectorView ? (
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={isToppingSelector}
                      onChange={() => setIsToppingSelector(!isToppingSelector)}
                    />
                    <span className="slider round"></span>
                  </label>
                ) : (
                  ""
                )}
                {isToppingSelectorView ? (
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={isToppingSelector}
                      onChange={() => setIsToppingSelector(!isToppingSelector)}
                    />
                    <span style={{ color: "blue", cursor: "pointer" }}>
                      view
                    </span>
                  </label>
                ) : (
                  ""
                )}
              </div>

              <div className="d-flex justify-content-between w-100 me-5">
                <h6>Add Bases</h6>
                {!isBaseSelectorView ? (
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={isBaseSelector}
                      onChange={() => setIsBaseSelector(!isBaseSelector)}
                    />
                    <span className="slider round"></span>
                  </label>
                ) : (
                  ""
                )}
                {isBaseSelectorView ? (
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={isBaseSelector}
                      onChange={() => setIsBaseSelector(!isBaseSelector)}
                    />
                    <span style={{ color: "blue", cursor: "pointer" }}>
                      view
                    </span>
                  </label>
                ) : (
                  ""
                )}
              </div>
            </div>
            {/* topping */}

            <div className="d-flex justify-content-center pb-5 mb-5">
              <Button
                onClick={() => navigate("/viewmenuitems")}
                className="btn btn-primary px-4 py-2 me-3"
              >
                BACK
              </Button>
              <Button type="submit" className="btn btn-primary px-4">
                UPDATE
              </Button>
            </div>
          </Col>
          {isToppingSelector ? (
            <div className="position-absolute left-0 w-75 text-dark p-2 rounded">
              <div className="toppings-selector">
                <button className="close-button" onClick={handleCloseClick}>
                  <FontAwesomeIcon icon={faXmark} />
                </button>
                <h2 className="heading">Select Toppings</h2>
                {showError && (
                  <p className="error-message">
                    Please provide prices for all selected toppings.
                  </p>
                )}
                <div className="toppings-list-container">
                  {toppings?.map((item) => (
                    <div key={item.toppingId} className="topping-item">
                      <input
                        type="checkbox"
                        id={item.toppingId}
                        value={item.toppingName}
                        onChange={() => handleCheckboxChange(item)}
                      />
                      <label htmlFor={item.toppingId}>{item.toppingName}</label>
                      {selectedToppings.some(
                        (topping) => topping.toppingId === item.toppingId
                      ) && (
                        <div>
                          <input
                            type="number"
                            placeholder="Enter price"
                            value={prices[item.toppingId] || ""}
                            onChange={(e) =>
                              handlePriceChange(item, e.target.value)
                            }
                            className={`price-input ${
                              prices[item.toppingId] === ""
                                ? "invalid-price"
                                : ""
                            }`}
                          />
                        </div>
                      )}
                    </div>
                  ))}
                </div>
                <div className="button-container">
                  <button className="cancel-button" onClick={handleCloseClick}>
                    Cancel
                  </button>
                  <button className="add-button" onClick={handleAddClick}>
                    Add
                  </button>
                </div>

                <style>
                  {`
                  .toppings-selector {
                    max-width: 800px;
                    margin: 0 auto;
                    padding: 20px;
                    border: 1px solid #ccc;
                    border-radius: 5px;
                    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
                    position: relative;
                    zindex:9999;
                    background:white
                  }

                  .heading {
                    font-size: 1.5rem;
                    text-align: center;
                    margin-bottom: 10px;
                  }

                  .toppings-list-container {
                    border: 1px solid #ccc;
                    padding: 10px;
                    margin-bottom: 20px;
                    display: flex;
                    flex-wrap: wrap;
                  }

                  .topping-item {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    width: calc(33.33% - 10px);
                    margin-bottom: 10px;
                    margin-right: 10px;
                  }

                  .topping-item:nth-child(3n) {
                    margin-right: 0;
                  }

                  .price-input {
                    width: 100px;
                    padding: 5px;
                    border: 1px solid #ccc;
                    border-radius: 3px;
                  }

                  .invalid-price {
                    border-color: red;
                  }

                  .selected-price {
                    margin-left: 10px;
                    font-size: 0.8rem;
                  }

                  .error-message {
                    color: red;
                    margin-bottom: 10px;
                  }

                  .close-button {
                    position: absolute;
                    top: 10px;
                    right: 10px;
                    padding: 5px;
                    background-color: gray;
                    color: white;
                    border: none;
                    border-radius: 3px;
                    cursor: pointer;
                  }
                  .button-container{
                    text-align:center
                  }
                  .cancel-button {
                    padding: 5px 10px;
                    background-color: gray;
                    color: white;
                    border: none;
                    border-radius: 3px;
                    cursor: pointer;
                    margin-right:10px;
                  }

                  .add-button {
                    margin: 0 auto;
                    margin-top: 10px;
                    padding: 5px 10px;
                    background-color: #007bff;
                    color: white;
                    border: none;
                    border-radius: 3px;
                    cursor: pointer;
                  }

                  @media screen and (max-width: 768px) {
                    .toppings-selector {
                      padding: 10px;
                    }

                    .topping-item {
                      width: calc(50% - 5px);
                    }

                    .topping-item:nth-child(3n) {
                      margin-right: 10px;
                    }

                    .topping-item:nth-child(2n) {
                      margin-right: 0;
                    }
                  }

                  @media screen and (max-width: 500px) {
                    .topping-item {
                      width: 100%;
                    }
                  }
                `}
                </style>
              </div>
            </div>
          ) : (
            ""
          )}

          {/* base */}
          {isBaseSelector ? (
            <div className="position-absolute w-75 text-dark p-2 rounded">
              <div className="bases-selector">
                <button className="close-button" onClick={handleBaseCloseClick}>
                  <FontAwesomeIcon icon={faXmark} />
                </button>
                <h2 className="heading">Select bases</h2>
                {showBaseError && (
                  <p className="error-message">
                    Please provide prices for all selected bases.
                  </p>
                )}
                <div className="bases-list-container">
                  {bases?.map((item) => (
                    <div key={item.baseId} className="base-item">
                      <input
                        type="checkbox"
                        id={item.baseId}
                        value={item.baseName}
                        onChange={() => handleBaseCheckboxChange(item)}
                      />
                      <label htmlFor={item.baseId}>{item.baseName}</label>
                      {selectedBases.some(
                        (base) => base.baseId === item.baseId
                      ) && (
                        <div>
                          <input
                            type="number"
                            placeholder="Enter price"
                            value={basePrices[item.baseId] || ""}
                            onChange={(e) =>
                              handleBasePriceChange(item, e.target.value)
                            }
                            className={`price-input ${
                              basePrices[item.baseId] === ""
                                ? "invalid-price"
                                : ""
                            }`}
                          />
                        </div>
                      )}
                    </div>
                  ))}
                </div>
                <div className="button-container">
                  <button
                    className="cancel-button"
                    onClick={handleBaseCloseClick}
                  >
                    Cancel
                  </button>
                  <button className="add-button" onClick={handleBaseAddClick}>
                    Add
                  </button>
                </div>
                <style>
                  {`
                  .bases-selector {
                    max-width: 800px;
                    margin: 0 auto;
                    padding: 20px;
                    border: 1px solid #ccc;
                    border-radius: 5px;
                    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
                    position: relative;
                    zindex:9999;
                    background:white
                  }

                  .heading {
                    font-size: 1.5rem;
                    text-align: center;
                    margin-bottom: 10px;
                  }

                  .bases-list-container {
                    border: 1px solid #ccc;
                    padding: 10px;
                    margin-bottom: 20px;
                    display: flex;
                    flex-wrap: wrap;
                  }

                  .base-item {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    width: calc(33.33% - 10px);
                    margin-bottom: 10px;
                    margin-right: 10px;
                  }

                  .base-item:nth-child(3n) {
                    margin-right: 0;
                  }

                  .price-input {
                    width: 100px;
                    padding: 5px;
                    border: 1px solid #ccc;
                    border-radius: 3px;
                  }

                  .invalid-price {
                    border-color: red;
                  }

                  .selected-price {
                    margin-left: 10px;
                    font-size: 0.8rem;
                  }

                  .error-message {
                    color: red;
                    margin-bottom: 10px;
                  }

                  .close-button {
                    position: absolute;
                    top: 10px;
                    right: 10px;
                    padding: 5px;
                    background-color: gray;
                    color: white;
                    border: none;
                    border-radius: 3px;
                    cursor: pointer;
                  }
                  .button-container{
                    text-align:center
                  }
                  .cancel-button {
                    padding: 5px 10px;
                    background-color: gray;
                    color: white;
                    border: none;
                    border-radius: 3px;
                    cursor: pointer;
                    margin-right:10px;
                  }

                  .add-button {
                    margin: 0 auto;
                    margin-top: 10px;
                    padding: 5px 10px;
                    background-color: #007bff;
                    color: white;
                    border: none;
                    border-radius: 3px;
                    cursor: pointer;
                  }

                  @media screen and (max-width: 768px) {
                    .bases-selector {
                      padding: 10px;
                    }

                    .base-item {
                      width: calc(50% - 5px);
                    }

                    .base-item:nth-child(3n) {
                      margin-right: 10px;
                    }

                    .base-item:nth-child(2n) {
                      margin-right: 0;
                    }
                  }

                  @media screen and (max-width: 500px) {
                    .base-item {
                      width: 100%;
                    }
                  }
                `}
                </style>
              </div>
            </div>
          ) : (
            ""
          )}
        </Row>
      </Form>
    </Container>
  );
}

export default UpdateMenuItems;
