import React, { useEffect, useState, useCallback } from "react";
import unknownImg from "./../../assests/images/unknown_dp_img.jpg";
import axios from "axios";
import Swal from "sweetalert2";
import { message } from "antd";

function PartnerDetails({ DeliveryPerson_ID, updateClose, choosedOption }) {
  const [deliveryPartner, setDeliveryPartner] = useState({
    DP_EmpID: "",
    DP_Name: "",
    DP_EMAIL_ID: "",
    DP_PhoneNumber: "",
    DP_Addressproof: "",
    Del_IDproof: "",
    approvalstatus: "",
  });

  const adminId = localStorage.getItem("fosadminsecretsID");

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_FOS_API}/getIndividualDeliveryPartnerDetails/${DeliveryPerson_ID}`, {
        headers: { Authorization: adminId },
      })
      .then((res) => {
        setDeliveryPartner(res.data);
      })
      .catch((err) => {
        console.error("Error fetching delivery partner details:", err);
        message.error("Server Error");
      });
  }, [DeliveryPerson_ID, adminId]);

  const handleUpdate = useCallback((e) => {
    e.preventDefault();
    axios
      .put(`${process.env.REACT_APP_FOS_API}/updateDeliveryPartner/${DeliveryPerson_ID}`, deliveryPartner, {
        headers: { Authorization: adminId },
      })
      .then(() => {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Update Successfully",
          showConfirmButton: false,
          timer: 1500,
        });
        setTimeout(updateClose, 1500);
      })
      .catch((err) => {
        console.error("Error updating delivery partner details:", err);
        message.error("Failed to update delivery partner details");
      });
  }, [DeliveryPerson_ID, adminId, deliveryPartner, updateClose]);

  const handleInActive = useCallback((e) => {
    e.preventDefault();
    Swal.fire({
      title: "Are you sure to In-Active?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, In-Active!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .put(`${process.env.REACT_APP_FOS_API}/setDpInActive/${DeliveryPerson_ID}`, {}, {
            headers: { Authorization: adminId },
          })
          .then((res) => {
            if (res.status === 200) {
              updateClose();
            }
          })
          .catch((err) => {
            console.error("Error setting delivery partner to inactive:", err);
            message.error("Failed to set delivery partner to inactive");
          });
      }
    });
  }, [DeliveryPerson_ID, adminId, updateClose]);

  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    setDeliveryPartner((prev) => ({
      ...prev,
      [name]: value,
    }));
  }, []);

  return (
    <div>
      <center>
        <h5 className="py-4 text-primary">
          <b>DELIVERY PARTNER INFO</b>
        </h5>
      </center>
      <div className="row container justify-content-center">
        <div className="col-12 col-md-4">
          <img src={unknownImg} alt="Delivery Partner" />
        </div>
        <div className="col-12 col-md-6">
          <form onSubmit={handleUpdate} className="">
            <div className="row">
              <div className="form-group mb-4 col-12 col-lg-6">
                <label htmlFor="DP_Name">DP Name</label>
                <input
                  onChange={handleChange}
                  value={deliveryPartner.DP_Name}
                  name="DP_Name"
                  type="text"
                  className="form-control"
                  required
                />
              </div>
              <div className="form-group mb-4 col-12 col-lg-6">
                <label htmlFor="DP_EmpID">DP EmpID</label>
                <input
                  onChange={handleChange}
                  value={deliveryPartner.DP_EmpID}
                  name="DP_EmpID"
                  className="form-control"
                  type="number"
                />
              </div>
            </div>

            <div className="row">
              <div className="form-group mb-4 col-12 col-lg-6">
                <label htmlFor="DP_EMAIL_ID">DP Email</label>
                <input
                  onChange={handleChange}
                  value={deliveryPartner.DP_EMAIL_ID}
                  name="DP_EMAIL_ID"
                  type="email"
                  className="form-control"
                  required
                />
              </div>
              <div className="form-group mb-4 col-12 col-lg-6">
                <label htmlFor="DP_PhoneNumber">DP Phone Number</label>
                <input
                  onChange={handleChange}
                  value={deliveryPartner.DP_PhoneNumber}
                  name="DP_PhoneNumber"
                  type="number"
                  className="form-control"
                  required
                />
              </div>
            </div>

            <div className="form-group mb-4">
              <label htmlFor="DP_Addressproof">DP Address Proof</label>
              <textarea
                onChange={handleChange}
                value={deliveryPartner.DP_Addressproof}
                name="DP_Addressproof"
                className="form-control"
                required
              />
            </div>

            <div className="form-group mb-2">
              <label htmlFor="Del_IDproof">DP ID Proof</label>
              <input
                onChange={handleChange}
                value={deliveryPartner.Del_IDproof}
                name="Del_IDproof"
                type="text"
                className="form-control"
                required
              />
            </div>

            <center>
              {["active", "in-active", "rejected"].includes(choosedOption) && (
                <div>
                  <button type="submit" className="btn btn-primary me-3 mt-4">
                    UPDATE
                  </button>
                  {choosedOption === "active" && (
                    <button onClick={handleInActive} className="btn btn-danger mt-4">
                      IN-ACTIVE
                    </button>
                  )}
                </div>
              )}
            </center>
          </form>
        </div>
      </div>
    </div>
  );
}

export default PartnerDetails;
