import axios from "axios";
import React, { useEffect, useState } from "react";
import "jspdf-autotable"; // Import the Autotable plugin for jsPDF
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import { message } from "antd";

function ViewOrder({ id }) {
  const [invoiceData, setInvoiceData] = useState({});
  const [addressData, setAddressData] = useState({});
  const [orderedList, setOrderedList] = useState([]);
  const adminId = localStorage.getItem("fosadminsecretsID");

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_FOS_API}/getinvoicedetails/` + id, {
        headers: { Authorization: adminId },
      })
      .then((res) => setInvoiceData(res.data))
      .catch((err) => {
        console.log(err);
        message.error("Server Error");
      });
  }, [id]);

  console.log(
    "invoice orderno -> " +
      invoiceData.orderNumber +
      " invoice addressId -> " +
      invoiceData.addressId
  );

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_FOS_API}/getorderedlist/` +
          invoiceData.orderNumber,
        {
          headers: { Authorization: adminId },
        }
      )
      .then((res) => setOrderedList(res.data))
      .catch((err) => {
        console.log(err);
        message.error("Server Error");
      });
  }, [invoiceData.orderNumber]);

  console.log("orderedList --> ", orderedList);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_FOS_API}/getaddress/` + invoiceData.addressId,
        {
          headers: { Authorization: adminId },
        }
      )
      .then((res) => setAddressData(res.data))
      .catch((err) => {
        console.log(err);
        message.error("Server Error");
      });
  }, [invoiceData.addressId]);

  const totalBill = invoiceData.totalBill;

  console.log("-->" + invoiceData.addressId);

  const subSum = orderedList.reduce((accumulator, currentValue) => {
    return accumulator + currentValue.price;
  }, 0);

  console.log("subsum" + subSum);

  return (
    <div className="container pt-4">
      <div className="px-4">
        <div class="d-flex justify-content-between">
          <h4>ORDER DETAILS :</h4>
          <p></p>
        </div>
        <div className="row">
          <div className="mb-3 col-md-6">
            <h2 className="py-2">Receiver :</h2>
            <div>
              <h5 className="fw-bold">{addressData.customername}</h5>
              <h6>{addressData.phnum}</h6>
            </div>
            <div className="address pt-2 pb-3">
              <h6>{addressData.address}</h6>
              <h6>{addressData.pincode}</h6>
            </div>
          </div>
          <div className="py-5 mt-2 col-md-6">
            <div className="pb-3">
              <b>Invoice Number :</b> {id}
            </div>
            <div className="pb-3">
              <b>Order Number :</b> {invoiceData.orderNumber}
            </div>
            <div>
              <td>
                <b>Invoice Date :</b>
                {new Date(invoiceData.invoiceDate).toLocaleString("en-US", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </td>
            </div>
          </div>
        </div>
        <div
          style={{
            maxHeight: "30vh",
            overflow: "auto",
            background: "white",
          }}
        >
          <table className="table table-bordered">
            <thead className="bg-light">
              <tr>
                <th>MenuItem Id</th>
                <th>MenuItem</th>
                <th>Qty</th>
                <th>Unit Cost</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {orderedList?.map((m, index) => (
                <tr key={index}>
                  <td>{m.menuitemid} </td>
                  <td>
                    <h6>{m.menuitems} {(m.base!=null)?<span>({m.base.baseName})</span>:""}</h6>
                    {m.toppings.length > 0
                      ? m.toppings?.map((n, index) => (
                          <div>
                            {n.toppingName} $({n.toppingPrice}),
                          </div>
                        ))
                      : ""}
                  </td>
                  <td>{m.quantity}</td>
                  <td>{m.semiTotal}</td>
                  <td>{m.price}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <h6 style={{ textAlign: "end" }} className="pt-3 pe-5">
          Tip cost : <span> $ </span>
          {invoiceData.tipsCost > 0 ? invoiceData.tipsCost : 0}
        </h6>
        <h6 style={{ textAlign: "end" }} className="pt-3 pe-5">
          Delivery cost : $ 50
        </h6>
        <h6 style={{ textAlign: "end" }} className="pt-3 pe-5">
          Tax : {((subSum / 100) * 5).toFixed(2)}
        </h6>
        <h5 style={{ textAlign: "end" }} className="pt-3 pe-5">
          <span style={{ border: "0.01px solid black", padding: "5px" }}>
            Total Cost :{" "}
            <span>
              <b>$ {totalBill}</b>
            </span>
          </span>
        </h5>
      </div>
    </div>
  );
}

export default ViewOrder;
