import React from "react";
import { Accordion } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DashboardCustomizeOutlinedIcon from '@mui/icons-material/DashboardCustomizeOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import LunchDiningOutlinedIcon from '@mui/icons-material/LunchDiningOutlined';
import DinnerDiningOutlinedIcon from '@mui/icons-material/DinnerDiningOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';

import {
  faChessBoard,
  faSquareMinus,
  faFolderTree,
  faFileInvoiceDollar,
  faTruck,
  faBars,
  faClipboardList,
  faLayerGroup,
  faFileInvoice
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

function SideBar() {
  const menuItem = [
    {
      name: "Menus",
      sub: [
        { cat: "Add Menu", path: "addmenu" },
        { cat: "Add Menu Items", path: "addmenuitems" },
        { cat: "View Menu", path: "viewmenu" },
        { cat: "View Menu Items", path: "viewmenuitems" },
      ],
      icon: <LunchDiningOutlinedIcon/>,  // Assuming you want to change faSquareMinus to faBars
    },
    {
      name: "Orders",
      sub: [
        { cat: "Order Pickup", path: "orderpickup" },
        { cat: "Pending Orders", path: "orderspending" },
        { cat: "Completed Orders", path: "orderscompleted" },
      ],
      icon: <AssignmentTurnedInOutlinedIcon/>,  // Assuming you want to change faFolderTree to faClipboardList
    },
    {
      name: "Invoice",
      sub: [{ cat: "Invoice List", path: "invoice" }],
      icon: <DescriptionOutlinedIcon/>,  // Assuming you want to change faFileInvoiceDollar to faFileInvoice
    },
    {
      name: "Toppings",
      sub: [
        { cat: "Add Topping", path: "addtopping" },
        { cat: "Add Base", path: "addbase" },
        { cat: "View Topping", path: "viewtopping" },
        { cat: "View Base", path: "viewbase" },
      ],
      icon: <DinnerDiningOutlinedIcon/>,  // Assuming you want to change faSquareMinus to faLayerGroup
    },
  ];
  
  return (
    <div className="pb-3 p-1">
      <Accordion className="py-2" defaultActiveKey={null}>
        <Accordion.Item className="py-2">
          <Link
            to={"/"}
            className="accordion-button-dashboard link p-4"
            style={{
              backgroundImage: "transparent",
              boxShadow: "none",
              border: "transparent",
              outline: "none",
              cursor: "pointer",
            }}
          >
            {/* <DashboardCustomizeOutlinedIcon/> */}
            <DashboardOutlinedIcon className="me-1"/>
            <span className="fs-5">Dashboard</span>
          </Link>
        </Accordion.Item>
      </Accordion>

      {menuItem.map((item) => (
        <Accordion className="py-2" defaultActiveKey={null}>
          <Accordion.Item>
            <Accordion.Header
              style={{
                boxShadow: "none",
                border: "transparent",
                outline: "none",
              }}
            >
              <span className="me-1">{item.icon}</span>
              <span className="fs-5 ">{item.name}</span>
            </Accordion.Header>
            <Accordion.Body>
              {item.sub.map((n) => (
                <h6 className="p-3">
                  <Link
                    className="link"
                    style={{ textDecoration: "none", color: "black" }}
                    to={n.path}
                  >
                    {n.cat}
                  </Link>
                </h6>
              ))}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      ))}

      <Accordion className="py-2" defaultActiveKey={null}>
        <Accordion.Item className="py-2">
          <Link
            to={"/viewdeliverypartner"}
            className="accordion-button-dashboard link p-4"
            style={{
              backgroundImage: "transparent",
              boxShadow: "none",
              border: "transparent",
              outline: "none",
              cursor: "pointer",
            }}
          >
              <LocalShippingOutlinedIcon className="me-1"/>
            
            <span className="fs-5">Delivery Partner</span>
          </Link>
        </Accordion.Item>
      </Accordion>
    </div>
  );
}

export default SideBar;
