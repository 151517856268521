import React, { useEffect, useReducer, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import Swal from "sweetalert2";
import ViewOrder from "./ViewOrder";
import { message } from "antd";

function OrderPending() {
  const [totalTodayOrders, setTotalTodayOrders] = useState([]);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [deliveryPersonActiveDetails, setDeliveryPersonActiveDetails] =
    useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);
  const adminId = localStorage.getItem("fosadminsecretsID");

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_FOS_API}/getactivedeliverypersondetails`, {
        headers: { Authorization: adminId },
      })
      .then((res) => setDeliveryPersonActiveDetails(res.data))
      .catch((err) => {
        console.log(err);
        message.error("Server Error");
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_FOS_API}/getinvoicedetails`, {
        headers: { Authorization: adminId },
      })
      .then((res) => setTotalTodayOrders(res.data));
  }, [reducerValue]);

  const filteredTodayOrders = totalTodayOrders.filter((element) => {
    return (
      element.orderstatus != "issues" &&
      element.orderstatus != "delivered" &&
      element.orderstatus != "rejected"
    );
  });

  const itemsPerPage = 7;
  const totalPages = Math.ceil(filteredTodayOrders.length / itemsPerPage);

  const paginatedOrders = filteredTodayOrders.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const filterAssignedDPName = (value) => {
    let DeliveryPersonName = null;
    deliveryPersonActiveDetails?.map((element) => {
      if (element.DeliveryPerson_ID === value) {
        DeliveryPersonName = element.DP_Name;
      }
    });
    console.log(DeliveryPersonName);
    return DeliveryPersonName;
  };

  const fetchData = (event) => {
    const selectedDPId = event.target.previousSibling.value;
    const invoiceNo = event.target.value;
    console.log(selectedDPId);
    if (selectedDPId === "not choosed") {
      alert("Choose DP");
    } else {
      axios
        .put(
          `${process.env.REACT_APP_FOS_API}/updateassignment`,
          { selectedDPId, invoiceNo },
          { headers: { Authorization: adminId } }
        )
        .then((res) => {
          if (res.data.success) console.log("success");
          else {
            console.log("failure");
          }
          forceUpdate();
          Swal.fire("Assigned Successfully!");
        })
        .then((err) => console.log(err));
    }
  };

  return (
    <div className="row vh-75">
      <div className="col-12">
        <h5 className="rounded p-3 bg-primary text-white ">
          Order status - Pending{" "}
          <FontAwesomeIcon className="ms-1" icon={faBell} />
        </h5>

        <table className="table">
          <thead>
            <tr>
              <th>#</th>
              <th>Customer Id</th>
              <th>Order status</th>
              <th>Total Bill</th>
              <th>Date & Time</th>
              <th>Order</th>
            </tr>
          </thead>
          <tbody>
            {paginatedOrders && paginatedOrders.length > 0
              ? paginatedOrders?.map((val, index) => (
                  <tr key={val.invoiceNumber}>
                    <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                    <td>{val.customerID}</td>
                    <td>{val.orderstatus}</td>
                    <td>$ {val.totalBill}</td>
                    <td>
                      {new Date(val.invoiceDate).toLocaleString("en-US", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                        hour: "2-digit",
                        minute: "2-digit",
                      })}
                    </td>

                    <td>
                      <button
                        onClick={() => {
                          setSelectedOrderId(val.invoiceNumber);
                        }}
                        className="btn btn-primary btn-sm"
                      >
                        View
                      </button>
                    </td>
                  </tr>
                ))
              : "No Orders"}
          </tbody>
        </table>

        <div
          className="pagination"
          style={{ display: "flex", justifyContent: "center" }}
        >
          {[...Array(totalPages)].map((_, index) => (
            <button
              key={index}
              onClick={() => handlePageChange(index + 1)}
              className={`mx-1 btn btn-sm ${
                currentPage === index + 1 ? "btn-primary" : "btn-secondary"
              }`}
            >
              {index + 1}
            </button>
          ))}
        </div>
      </div>

      {selectedOrderId != null ? (
        <div
          className="border rounded"
          style={{
            position: "absolute",
            top: "5%",
            left: "5%",
            background: "white",
            height: "90%",
            width: "90%",
          }}
        >
          <div
            onClick={() => setSelectedOrderId(null)}
            style={{
              position: "absolute",
              right: "2%",
              top: "3%",
              fontWeight: "600",
              fontSize: "30px",
              cursor: "pointer",
            }}
          >
            X
          </div>
          <ViewOrder id={selectedOrderId} />
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default OrderPending;
