import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import jsPDF from "jspdf";
import "jspdf-autotable";
import "bootstrap/dist/css/bootstrap.min.css";
import { ArrowLeftOutlined } from "@ant-design/icons";


function InvoiceDetails() {
  const { id } = useParams();
  const [invoiceData, setInvoiceData] = useState({});
  const [addressData, setAddressData] = useState({});
  const [orderedList, setOrderedList] = useState([]);
  const adminId = localStorage.getItem("fosadminsecretsID");

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_FOS_API}/getinvoicedetails/${id}`, {
        headers: { Authorization: adminId },
      })
      .then((res) => setInvoiceData(res.data))
      .catch((err) => console.log(err));
  }, [id, adminId]);

  useEffect(() => {
    if (invoiceData.orderNumber) {
      axios
        .get(`${process.env.REACT_APP_FOS_API}/getorderedlist/${invoiceData.orderNumber}`, {
          headers: { Authorization: adminId },
        })
        .then((res) => setOrderedList(res.data))
        .catch((err) => console.log(err));
    }
  }, [invoiceData.orderNumber, adminId]);

  useEffect(() => {
    if (invoiceData.addressId) {
      axios
        .get(`${process.env.REACT_APP_FOS_API}/getaddress/${invoiceData.addressId}`, {
          headers: { Authorization: adminId },
        })
        .then((res) => setAddressData(res.data))
        .catch((err) => console.log(err));
    }
  }, [invoiceData.addressId, adminId]);

  //* Download as PDF
  const handlePrintAndDownload = () => {
    const doc = new jsPDF();
    doc.setTextColor(0, 0, 0);
  
    // Set background color for the address and other details section
    doc.setFillColor(240, 240, 240);
    doc.rect(10, 20, 190, 50, 'F');
  
    // Company details at the top
    doc.setFontSize(20);
    doc.setTextColor(255, 0, 0); // Set color to red
    doc.text("Invoice Bill", 105, 10, "center");
    
    doc.setFontSize(14);
    doc.setTextColor(0, 0, 255); // Set color to blue
    doc.text("Company Name", 20, 30);
  
    doc.setFontSize(12);
    doc.setTextColor(0, 0, 0); // Reset color to black
    doc.text("1234 Street Name, City, State, Zip Code", 20, 40);
    doc.text("Phone: (123) 456-7890", 20, 50);
    doc.text("Email: info@company.com", 20, 60);
  
    // Add the header with 'Invoice Bill'
    // Left section: Receiver details
    doc.setFontSize(12);
    doc.setTextColor(0, 0, 0); // Reset color to black
    doc.text("Receiver:", 20, 90);
    doc.setFontSize(14);
    doc.text(addressData.customername, 20, 100);
    doc.setFontSize(12);
    doc.text(addressData.phnum, 20, 110);
  
    // Right section: Invoice details
    doc.setFontSize(12);
    doc.text(`Invoice Number: ${id}`, 100, 90);
    doc.text(`Order Number: ${invoiceData.orderNumber}`, 100, 100);
    doc.text(`Invoice Date: ${invoiceData.invoiceDate}`, 100, 110);
  
    // Ordered data as a table
    const tableData = [];
    const headers = ["MenuItem Id", "MenuItem", "Qty", "Unit Cost", "Total"];
  
    orderedList.forEach((item) => {
      tableData.push([
        item.menuitemid,
        item.menuitems,
        item.quantity,
        item.semiTotal,
        (item.price).toFixed(2),
      ]);
    });
  
    doc.autoTable({
      startY: 130,
      head: [headers],
      body: tableData,
      theme: "striped",
      headStyles: { fillColor: [22, 160, 133] },
      styles: { halign: "center" },
    });
  
    // Total Calculation
    const subtotal = orderedList.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.price;
    }, 0);
    const deliveryCost = 50;
    const tipCost = invoiceData.tipsCost > 0 ? invoiceData.tipsCost : 0;
    const tax = (subtotal* 0.05).toFixed(2); 
    const finalTotal = (subtotal + parseFloat(tax) + deliveryCost + tipCost).toFixed(2);
  
    const finalY = doc.autoTable.previous.finalY + 10;
  
    doc.setFontSize(12);
    doc.text(`Tip Cost: $${tipCost}`, 20, finalY);
    doc.text(`Delivery Cost: $${deliveryCost}`, 20, finalY + 10);
    doc.text(`Tax: $${tax}`, 20, finalY + 20);
    doc.setFontSize(14);
    doc.text(`Total Cost: $${finalTotal}`, 20, finalY + 30);
  
    // Save the PDF
    doc.save("invoice.pdf");
  };
  
  

  const totalBill = orderedList.reduce((accumulator, currentValue) => {
    return accumulator + currentValue.price;
  }, 0);
  const tax = ((totalBill/100)*5).toFixed(2); // 10% tax
  const deliveryCost = 50;
  const tipCost = invoiceData.tipsCost > 0 ? invoiceData.tipsCost : 0;
  const finalTotal = (totalBill + parseFloat(tax) + deliveryCost + tipCost).toFixed(2);
  console.log(tax,"tax");


  return (
    <div className="container pt-4">
      <div className="px-4">
        <div className="d-flex justify-content-between align-items-center">
          <Link to="/invoice" className="link text-primary fs-5">
            <ArrowLeftOutlined style={{ fontSize: "16px" }} /> Back
          </Link>
          <h4 className="mb-0">INVOICE BILL</h4>
        </div>
        <hr />
        <div className="d-flex justify-content-between">
          <div className="mb-3">
            <h5 className="py-2">Receiver : ({invoiceData.purchasemode})</h5>
            <div>
              <p className="mb-1">
                <strong>{addressData.customername}</strong>
              </p>
              <p className="mb-1">{addressData.phnum}</p>
            </div>
          </div>
          <div>
            <div className="pb-3">
              <b>Invoice Number :</b> {id}
            </div>
            <div className="pb-3">
              <b>Order Number :</b> {invoiceData.orderNumber}
            </div>
            <div>
              <p>
                <b>Invoice Date :</b> {invoiceData.invoiceDate}
              </p>
            </div>
          </div>
        </div>
        <div className="table-responsive">
          <table className="table table-bordered">
            <thead className="bg-light">
              <tr>
                <th>MenuItem Id</th>
                <th>MenuItem</th>
                <th>Qty</th>
                <th>Unit Cost</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {orderedList.map((m, index) => (
                <tr key={index}>
                  <td>{m.menuitemid}</td>
                  <td>
                    {m.menuitems}
                    {m.toppings.length > 0
                      ? m.toppings.map((n, idx) => (
                          <div key={idx}>
                            {n.toppingName} ($ {n.toppingPrice}),
                          </div>
                        ))
                      : ""}
                  </td>
                  <td>{m.quantity}</td>
                  <td>{m.semiTotal}</td>
                  <td>{m.price.toFixed(2)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="d-flex justify-content-end">
          <div className="text-end">
            {invoiceData.purchasemode == "delivery" ? (
              <>
                <p className="mb-1">Tip cost: $ {tipCost}</p>
                <p className="mb-1">Delivery cost: $ {deliveryCost}</p>
              </>
            ) : (
              ""
            )}
            <p className="mb-1">Tax: $ {tax}</p>
            <h5 className="mb-0">Total Cost: $ {invoiceData.totalBill}</h5>
          </div>
        </div>
        <div className="d-flex justify-content-end mt-3 mb-5 pb-5">
          <button
            onClick={handlePrintAndDownload}
            className="btn px-3"
            style={{
              background: "orange",
              height: "2.5rem",
              color: "white",
              cursor: "pointer",
            }}
          >
            Download Invoice
          </button>
        </div>
      </div>
    </div>
  );
}

export default InvoiceDetails;
