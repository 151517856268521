import axios from "axios";
import React, { useState, useCallback } from "react";
import Swal from "sweetalert2";

function AddPartner() {
  const [formData, setFormData] = useState({
    DP_EmpID: "",
    DP_Name: "",
    DP_EMAIL_ID: "",
    DP_PhoneNumber: "",
    DP_Addressproof: "",
    Del_IDproof: "",
  });

  const approvalstatus = "approved";
  const adminId = localStorage.getItem("fosadminsecretsID");

  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }, []);

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    axios
      .post(
        `${process.env.REACT_APP_FOS_API}/registerdeliverypartner`,
        { ...formData, approvalstatus },
        { headers: { Authorization: adminId } }
      )
      .then((res) => {
        setTimeout(() => {
          window.location.reload();
        }, 2000);
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Registered",
          showConfirmButton: false,
          timer: 2000,
        });
      })
      .catch((err) => {
        console.error("Error registering delivery partner:", err);
        Swal.fire({
          icon: "error",
          title: "Registration Failed",
          text: "Please try again later.",
        });
      });
  }, [formData, adminId]);

  return (
    <div className="border py-2 w-100">
      <center>
        <h5 className="py-4 text-primary">
          <b>ADD DELIVERY PARTNER</b>
        </h5>
      </center>
      <form onSubmit={handleSubmit} className="mt-4 w-100">
        <div className="container">
          <div className="row justify-content-center w-100">
            <div className="col-md-10">
              <div className="row">
                <div className="form-group mb-4 col-12 col-lg-6">
                  <label htmlFor="DP_Name">DP Name</label>
                  <input
                    name="DP_Name"
                    onChange={handleChange}
                    value={formData.DP_Name}
                    type="text"
                    className="form-control"
                    required
                  />
                </div>
                <div className="form-group mb-4 col-12 col-lg-6">
                  <label htmlFor="DP_EmpID">DP EmpID</label>
                  <input
                    name="DP_EmpID"
                    onChange={handleChange}
                    value={formData.DP_EmpID}
                    className="form-control"
                    type="number"
                  />
                </div>
              </div>

              <div className="row">
                <div className="form-group mb-4 col-12 col-lg-6">
                  <label htmlFor="DP_EMAIL_ID">DP Email</label>
                  <input
                    name="DP_EMAIL_ID"
                    onChange={handleChange}
                    value={formData.DP_EMAIL_ID}
                    type="email"
                    className="form-control"
                    required
                  />
                </div>
                <div className="form-group mb-4 col-12 col-lg-6">
                  <label htmlFor="DP_PhoneNumber">DP Phone Number</label>
                  <input
                    name="DP_PhoneNumber"
                    onChange={handleChange}
                    value={formData.DP_PhoneNumber}
                    type="number"
                    className="form-control"
                    required
                  />
                </div>
              </div>

              <div className="form-group mb-4">
                <label htmlFor="DP_Addressproof">DP Address Proof</label>
                <textarea
                  name="DP_Addressproof"
                  onChange={handleChange}
                  value={formData.DP_Addressproof}
                  className="form-control"
                  required
                />
              </div>

              <div className="form-group mb-2">
                <label htmlFor="Del_IDproof">DP ID Proof</label>
                <input
                  name="Del_IDproof"
                  onChange={handleChange}
                  value={formData.Del_IDproof}
                  type="text"
                  className="form-control"
                  required
                />
              </div>

              <center>
                <button type="submit" className="btn btn-primary mt-4">
                  ADD NOW
                </button>
              </center>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default AddPartner;
