import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import yorksun from '../../assests/images/logo.png'

function TopNavbar() {
  const [adminID, setAdminID] = useState(0);
  const [lastActivity, setLastActivity] = useState(Date.now());
  const navigate = useNavigate();

  useEffect(() => {
    setAdminID(localStorage.getItem('fosadminsecretsID'));

    const events = ['mousemove', 'mousedown', 'click', 'scroll', 'keypress'];

    const resetTimer = () => {
      setLastActivity(Date.now());
    };

    events.forEach(event => {
      window.addEventListener(event, resetTimer);
    });

    const interval = setInterval(() => {
      if (Date.now() - lastActivity > 5* 60 * 1000) { // 5 minutes
        handleAutoLogout();
      }
    }, 1000);

    return () => {
      events.forEach(event => {
        window.removeEventListener(event, resetTimer);
      });
      clearInterval(interval);
    };
  }, [lastActivity]);

  const handleAutoLogout = () => {
    localStorage.removeItem("fosadminsecretsID");
    navigate("/");
    window.location.reload();
  };

  const handleLogoutClick = () => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: false
    });

    swalWithBootstrapButtons.fire({
      title: 'Are you sure?',
      text: "",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, LogOut!',
      cancelButtonText: 'No, Stay in!',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        handleAutoLogout();
      }
    });
  };

  return (
    <div  className='d-flex justify-content-between pe-3 border-bottom p-2 bg-light'>
      {/* <h3>FOS </h3> */}
      <img className='ms-2' style={{width:'150px'}} src={yorksun}></img>
      
      {
        (adminID?.length > 0)
          ?<div>
             <button onClick={handleLogoutClick} className='btn btn-outline-danger mt-2'>
                <span><span className='me-2'>logout</span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-box-arrow-in-right" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M6 3.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 0-1 0v2A1.5 1.5 0 0 0 6.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-8A1.5 1.5 0 0 0 5 3.5v2a.5.5 0 0 0 1 0z"/>
                    <path fillRule="evenodd" d="M11.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H1.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708z"/>
                  </svg>
                </span>
              </button>
          </div>
          : <div >
            <FontAwesomeIcon className='fs-5 text-dark mt-3' icon={faGear} />
          </div>
      }
    </div>
  );
}

export default TopNavbar;
